import { uiTabNames } from "../../config/constants";

export const spinner = {
  add: () => {
    return {
      type: 'ADD_SPINNER',
    }
  },
  subtract: () => {
    return {
      type: 'SUBTRACT_SPINNER',
    }
  }
};

export const slideDrawer = {
  add: () => {
    return {
      type: 'ADD_SLIDE_DRAWER',
    }
  },
  subtract: () => {
    return {
      type: 'SUBTRACT_SLIDE_DRAWER',
    }
  },
  addDatasuiteDetails: () => {
    return {
      type: 'ADD_DS_DETAILS_SLIDE_DRAWER'
    }
  },
  subtractDatasuiteDetails: () => {
    return {
      type: 'SUBTRACT_DS_DETAILS_SLIDE_DRAWER'
    }
  },
  addComp: () => {
    return {
      type: 'ADD_COMP_SLIDE_DRAWER',
    }
  },
  setCompData: (data) => {
    return {
      type: 'SET_COMP_DATA_SLIDE_DRAWER',
      payload: {
        data
      }
    }
  },
  subtractComp: () => {
    return {
      type: 'SUBTRACT_COMP_SLIDE_DRAWER',
    }
  },
  addMfr: () => {
    return {
      type: 'ADD_MFR_SLIDE_DRAWER',
    }
  },
  setMfrData: (data) => {
    return {
      type: 'SET_MFR_DATA_SLIDE_DRAWER',
      payload: {
        data
      }
    }
  },
  subtractMfr: () => {
    return {
      type: 'SUBTRACT_MFR_SLIDE_DRAWER',
    }
  },
  addOpp: () => {
    return {
      type: 'ADD_OPP_SLIDE_DRAWER',
    }
  },
  setOppData: (data) => {
    return {
      type: 'SET_OPP_DATA_SLIDE_DRAWER',
      payload: {
        data
      }
    }
  },
  subtractOpp: () => {
    return {
      type: 'SUBTRACT_OPP_SLIDE_DRAWER',
    }
  },
  addProfile: () => {
    return {
      type: 'ADD_PROFILE_SLIDE_DRAWER',
    }
  },
  subtractProfile: () => {
    return {
      type: 'SUBTRACT_PROFILE_SLIDE_DRAWER',
    }
  },
  addHelp: () => {
    return {
      type: 'ADD_HELP_SLIDE_DRAWER',
    }
  },
  subtractHelp: () => {
    return {
      type: 'SUBTRACT_HELP_SLIDE_DRAWER',
    }
  },
  addMobileMenu: () => {
    return {
      type: 'ADD_MOBILEMENU_SLIDE_DRAWER',
    }
  },
  subtractMobileMenu: () => {
    return {
      type: 'SUBTRACT_MOBILEMENU_SLIDE_DRAWER',
    }
  },
  addNotifications: () => {
    return {
      type: 'ADD_NOTIFICATIONS_SLIDE_DRAWER',
    }
  },
  subtractNotifications: () => {
    return {
      type: 'SUBTRACT_NOTIFICATIONS_SLIDE_DRAWER',
    }
  },
  addExport: () => {
    return {
      type: 'ADD_EXPORT_SLIDE_DRAWER',
    }
  },
  subtractExport: () => {
    return {
      type: 'SUBTRACT_EXPORT_SLIDE_DRAWER',
    }
  },
};

export const ciDetailsTabs = {
  setMfrPurchasesTab: () => {
    return {
      type: 'SET_CIDETAILS_MFR_TAB',
    }
  },
  setCompsPurchasesTab: () => {
    return {
      type: 'SET_CIDETAILS_COMPS_TAB',
    }
  }
};

export const linkNavigationBar = {
  setLink: (width, left) => {
    return {
      type: 'SET_LINK_NAVIGATION_BAR_LINK',
      payload: {
        width,
        left
      }
    }
  },
  setTab: (tabName) => {
    if(tabName === uiTabNames.campaignManagement) {
      return {
        type: 'SET_CAMPAIGN_MANAGEMENT_TAB'
      }
    } else {
      return {
        type: 'SET_DASHBOARD_TAB'
      }
    }
  },
  setNavbarWidth: (width) => {
    return {
      type: 'SET_NAVBAR_WIDTH',
      payload: width
    }
  }
};

export const campaignManagementNavigationBar = {
  setLink: (width, left) => {
    return {
      type: 'SET_CAMPAIGN_MANAGEMENT_NAVIGATION_BAR_LINK',
      payload: {
        width,
        left
      }
    }
  },
  setTab: (tabName) => {
    if(tabName === uiTabNames.opportunityStatus) {
      return {
        type: 'SET_CM_OPPORTUNITIES_STATUS_TAB'
      }
    } else {
      return {
        type: 'SET_CM_OPPORTUNITIES_OVERVIEW_TAB'
      }
    }
  }
};

export const categoriesProductsNavigationBar = {
  setLink: (width, left) => {
    return {
      type: 'SET_CATEGORIES_PRODUCTS_NAVIGATION_BAR_LINK',
      payload: {
        width,
        left
      }
    }
  },
  setTab: (tabName) => {
    if(tabName === uiTabNames.topProducts) {
      return {
        type: 'SET_DASHBOARD_TOP_PRODUCTS_TAB'
      }
    } else {
      return {
        type: 'SET_DASHBOARD_TOP_CATEGORIES_TAB'
      }
    }
  }
};


export const newLostLocationsNavigationBar = {
  setLink: (width, left) => {
    return {
      type: 'SET_NEW_LOST_LOCATIONS_NAVIGATION_BAR_LINK',
      payload: {
        width,
        left
      }
    }
  },
  setTab: (tabName) => {
    if(tabName === uiTabNames.lostLocations) {
      return {
        type: 'SET_DASHBOARD_LOST_LOCATIONS_TAB'
      }
    } else {
      return {
        type: 'SET_DASHBOARD_NEW_LOCATIONS_TAB'
      }
    }
  }
};

export const setExportButtonActive = (data) => {
  return {
    type: 'SET_EXPORT_BUTTON_ACTIVE',
    payload: data
  }
};