export default function reducer(state = {
  spinner: 0,
  slideDrawer: false,
  profileSlideDrawer: false,
  helpSlideDrawer: false,
  mobileMenuSlideDrawer: false,
  notificationsSlideDrawer: false,
  exportSlideDrawer: false,
  datasuiteWidgetDetailsSlideDrawer: false,
  compSlideDrawer: false,
  compSlideDrawerData: [],
  mfrSlideDrawer: false,
  mfrSlideDrawerData: [],
  oppSlideDrawer: false,
  oppSlideDrawerData: [],
  oppsFiltersSlideDrawer: false,
  mfrPurchasesTab: true,
  compsPurchasesTab: false,
  dashboardTab: true,
  campaignManagementTab: false,
  oppsOverviewTab: true,
  oppsStatusTab: false,
  topCategoriesTab: true,
  topProductsTab: false,
  newLocationsTab: true,
  lostLocationsTab: false,
  exportButtonActive: true,
  navbarWidth: 90
}, action) {
  switch(action.type) {
    case 'SET_NAVBAR_WIDTH':
      return {
        ...state,
        navbarWidth: action.payload
      };
    case 'ADD_SPINNER':
      return {
        ...state,
        spinner: state.spinner + 1
      };
    case 'SUBTRACT_SPINNER':
      return {
        ...state,
        spinner: state.spinner - 1
      };
    case 'ADD_SLIDE_DRAWER':
      return {
        ...state,
        slideDrawer: true
      };
    case 'SUBTRACT_SLIDE_DRAWER':
      return {
        ...state,
        slideDrawer: false
      };
    case 'ADD_PROFILE_SLIDE_DRAWER':
      return {
        ...state,
        profileSlideDrawer: true
      };
    case 'SUBTRACT_PROFILE_SLIDE_DRAWER':
      return {
        ...state,
        profileSlideDrawer: false
      };
    case 'ADD_HELP_SLIDE_DRAWER':
      return {
        ...state,
        helpSlideDrawer: true
      };
    case 'SUBTRACT_HELP_SLIDE_DRAWER':
      return {
        ...state,
        helpSlideDrawer: false
      };
    case 'ADD_MOBILEMENU_SLIDE_DRAWER':
      return {
        ...state,
        mobileMenuSlideDrawer: true
      };
    case 'SUBTRACT_MOBILEMENU_SLIDE_DRAWER':
      return {
        ...state,
        mobileMenuSlideDrawer: false
      };   
    case 'ADD_NOTIFICATIONS_SLIDE_DRAWER':
      return {
        ...state,
        notificationsSlideDrawer: true
      };
    case 'SUBTRACT_NOTIFICATIONS_SLIDE_DRAWER':
      return {
        ...state,
        notificationsSlideDrawer: false
      };  
    case 'ADD_EXPORT_SLIDE_DRAWER':
      return {
        ...state,
        exportSlideDrawer: true
      };
    case 'SUBTRACT_EXPORT_SLIDE_DRAWER':
      return {
        ...state,
        exportSlideDrawer: false
      };  
    case 'ADD_DS_DETAILS_SLIDE_DRAWER':
      return {
        ...state,
        datasuiteWidgetDetailsSlideDrawer: true
      };
    case 'SUBTRACT_DS_DETAILS_SLIDE_DRAWER':
      return {
        ...state,
        datasuiteWidgetDetailsSlideDrawer: false
      };
    case 'ADD_COMP_SLIDE_DRAWER':
      return {
        ...state,
        compSlideDrawer: true
      };
    case 'SUBTRACT_COMP_SLIDE_DRAWER':
      return {
        ...state,
        compSlideDrawer: false
      };
    case 'SET_COMP_DATA_SLIDE_DRAWER':
      return {
        ...state,
        compSlideDrawerData: action.payload
      };
    case 'ADD_MFR_SLIDE_DRAWER':
      return {
        ...state,
        mfrSlideDrawer: true
      };
    case 'SUBTRACT_MFR_SLIDE_DRAWER':
      return {
        ...state,
        mfrSlideDrawer: false
      };
    case 'SET_MFR_DATA_SLIDE_DRAWER':
      return {
        ...state,
        mfrSlideDrawerData: action.payload
      };
    case 'ADD_OPP_SLIDE_DRAWER':
      return {
        ...state,
        oppSlideDrawer: true
      };
    case 'SUBTRACT_OPP_SLIDE_DRAWER':
      return {
        ...state,
        oppSlideDrawer: false
      };
    case 'SET_OPP_DATA_SLIDE_DRAWER':
      return {
        ...state,
        oppSlideDrawerData: action.payload
      };
    case 'SET_CIDETAILS_MFR_TAB':
    return {
      ...state,
      mfrPurchasesTab: true,
      compsPurchasesTab: false
    };
    case 'SET_CIDETAILS_COMPS_TAB':
      return {
        ...state,
        mfrPurchasesTab: false,
        compsPurchasesTab: true
      };
    case 'SET_DASHBOARD_TAB':
      return {
        ...state,
        dashboardTab: true,
        campaignManagementTab: false
      };
    case 'SET_CAMPAIGN_MANAGEMENT_TAB':
      return {
        ...state,
        dashboardTab: false,
        campaignManagementTab: true
      };
    case 'SET_CM_OPPORTUNITIES_OVERVIEW_TAB':
      return {
        ...state,
        oppsOverviewTab: true,
        oppsStatusTab: false
      };
    case 'SET_CM_OPPORTUNITIES_STATUS_TAB':
      return {
        ...state,
        oppsOverviewTab: false,
        oppsStatusTab: true
      };
    case 'SET_DASHBOARD_TOP_CATEGORIES_TAB':
      return {
        ...state,
        topProductsTab: false,
        topCategoriesTab: true
      };
    case 'SET_DASHBOARD_TOP_PRODUCTS_TAB':
      return {
        ...state,
        topProductsTab: true,
        topCategoriesTab: false
      };
    case 'SET_DASHBOARD_NEW_LOCATIONS_TAB':
      return {
        ...state,
        newLocationsTab: true,
        lostLocationsTab: false
      };
    case 'SET_DASHBOARD_LOST_LOCATIONS_TAB':
      return {
        ...state,
        newLocationsTab: false,
        lostLocationsTab: true
      };
    case 'SET_EXPORT_BUTTON_ACTIVE': 
      return {
        ...state,
        exportButtonActive: action.payload
      };
    default:
      return state;
  };
};
