import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logout from './Logout/Logout';
import Settings from './Settings/Settings';
import Help from './Help/Help';
import MarketingCampaignsDetails from './MarketingCampaigns/MarketingCampaignDetails/MarketingCampaignsDetails';
import DashboardMobile from './Dashboard/DashboardMobile';
import MarketingCampaignsMobile from './MarketingCampaigns/MarketingCampaignsMobile';
import LoginMobile from '../views/Login/LoginMobile';
import DataSuiteMobile from './DataSuite/DataSuiteMobile';
import DataSuiteDetails from './DataSuite/DataSuiteDetails';
import GrowthOpportunitiesMobile from './DataSuite/GrowthOpportunitiesMobile';

const AppMainMobileRouter = () => {
  const chainData = useSelector(({user}) => user.chainData);
  const emergingData = useSelector(({user}) => user.emergingData);

  const dataSuiteAccess = chainData || emergingData;
  return (
    <Routes>
      <Route exact path='/login' element={<LoginMobile/>} />
      <Route exact path='/dashboard' element={<DashboardMobile />} />
      <Route exact path='/logout' element={<Logout />} />
      <Route exact path='/settings' element={<Settings />} />
      <Route exact path='/help' element={<Help />} />
      <Route exact path='/campaignmanagement' element={<MarketingCampaignsMobile />} />
      <Route path='/campaignmanagement/:id' element={<MarketingCampaignsDetails />} />
      {dataSuiteAccess && <Route exact path='/datasuite' element={<DataSuiteMobile />} />}
      {dataSuiteAccess && <Route exact path='/datasuite/details' element={<DataSuiteDetails />} />}
      <Route exact path='/growthopportunities' element={<GrowthOpportunitiesMobile />} />
      <Route path="*" element={<Navigate to='/dashboard' replace={true} />} />
    </Routes>
  );
};

export default AppMainMobileRouter;