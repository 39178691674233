import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, ScrollArea } from '@mantine/core';
import { LinksGroup } from './NavBarLinksGroup.tsx';
import useTopBar from './useTopBar';
import classes from './TopBar.module.css'
import { isMobile, isMobileOnly } from 'react-device-detect';
import { slideDrawer } from '../../reducers/UIReducer/UIActions.js';
import { useDispatch } from 'react-redux';
import { FiX } from 'react-icons/fi';
import { HiOutlineMenuAlt1 } from "react-icons/hi";

const TopBar = () => {
  const navigate = useNavigate();
  const logoLocation = localStorage.getItem('branding') === 'ARROWSTREAM' ? './arrowstreamLogo.png' : './bepLogo.png'
  const { links, bottomLinks } = useTopBar.useLinks();
  const goToHome = () => {
    if(localStorage.getItem('branding') === 'ARROWSTREAM' && !isMobile) {
      localStorage.setItem('branding', 'BEP');
    } else if(localStorage.getItem('branding') === 'BEP' && !isMobile) {
      localStorage.setItem('branding', 'ARROWSTREAM');
    } else {
      // DO NOTHING
    }
    navigate('/');
  };

  const toplinksMap = links.map((item) => <LinksGroup {...item} key={item.label} />);
  const bottomLinksMap = bottomLinks.map((item) => <LinksGroup {...item} key={item.label} />);
  const dispatch = useDispatch();

  return (
    <nav className=  {(isMobileOnly ? classes.navbarmobile : classes.navbar)} >
        {isMobile ? (
          <div className={classes.header}>
            <Group style={{display: 'flex', justifyContent: 'center', position: 'relative'}}>  
              <img src={logoLocation} alt='Company Icon' className='company-icon' style={{cursor:'pointer'}} onClick={() => goToHome()}/>
              {isMobileOnly ? 
              <HiOutlineMenuAlt1 style={{color: 'black', position: 'absolute', left: '0', fontSize: '38px'}} className="clickable" onClick={() => dispatch(slideDrawer.subtractMobileMenu())}></HiOutlineMenuAlt1>  :
              <FiX style={{color: 'black', position: 'absolute', right: '0'}} className="clickable" onClick={() => dispatch(slideDrawer.subtractMobileMenu())}></FiX> 
              }
              
              </Group>
          </div>
          ):(
            <div className={classes.header}>
            <Group justify="center">  
              <img src={logoLocation} alt='Company Icon' className='company-icon' style={{cursor:'pointer'}} onClick={() => goToHome()}/>
              </Group>
          </div>
          )
        }

      <ScrollArea className={classes.links}>
        <div className={classes.linksInner}onClick={() => dispatch(slideDrawer.subtractMobileMenu()) }>{toplinksMap}</div>
      </ScrollArea>

      <div className={classes.linksBottom}>{bottomLinksMap}</div>
    </nav>
  );
};


export default TopBar;