import React from "react";
import { Card, Col, Row, Spinner,  ModalHeader, ModalBody, Modal, ModalFooter, Button } from "reactstrap";
import { IoMdInformationCircle } from "react-icons/io";
import ReactSelect, { components } from "react-select";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import formatters from "../../../config/formatters";
import useDashboard from "../useDashboard";
import { isMobile, isMobileOnly } from "react-device-detect";
import '@mantine/carousel/styles.css';
import { Carousel } from '@mantine/carousel';
import { useState, useEffect } from 'react';
import { BsCaretDownFill } from "react-icons/bs";
import { selectStyles, Theme } from "../../../config/constants";

const HighlightsCard = () => {
    const highlightsData = useSelector(({dashboard}) => dashboard.highlights);
    const isLoading = useSelector(({dashboard}) => dashboard.highlightsIsLoading);
    const { usCurrencyFormatter, numberFormatter } = formatters();
    const { onChangeHighlightsFilter } = useDashboard.useDates();
    const [carouselIndex, setCarouselIndex] = useState(0);
    const highlightsOptions = [
        {value: 'Total Volume', label: 'Show: Total Volume'},
        {value: 'BEPL Business', label: 'Show: BEPL Business'},
        {value: 'Direct Business', label: 'Show: Direct Business'}
    ];
    useEffect(() => {
        onChangeHighlightsFilter(highlightsOptions[carouselIndex].value);
        // eslint-disable-next-line
    }, [carouselIndex]);

    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
            <BsCaretDownFill />
            </components.DropdownIndicator>
        );
    };

    const InputOption = ({
        getStyles,
        Icon,
        isDisabled,
        isFocused,
        isSelected,
        children,
        innerProps,
        ...rest
      }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        let textColor = "inherit";
        if (isDisabled) 
            { 
                bg = "rgb(230, 230, 230)";
                textColor= "rgb(77, 77, 77)";
            }
        if (isFocused && !isDisabled) bg = Theme().colors.accentLight;
        if (isActive) bg = Theme().colors.accent;

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: textColor,
            display: "flex "
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };
        return (
            <components.Option
              {...rest}
              isDisabled={isDisabled}
              isFocused={isFocused}
              isSelected={isSelected}
              getStyles={getStyles}
              innerProps={props}
            >
              {children}
            </components.Option>
        );
      };
  

    if (isMobileOnly) {
        return (  
            <Col>
                <Carousel withIndicators height={150} withControls={true} slideGap={4} controlsOffset="md" includeGapInSize={true} className='control' style={{zIndex: '0'}}>
                    <Carousel.Slide >
                        <Card style={{  backgroundColor: '#CF3F7C', height: '110px', marginLeft: '0px', marginRight: '0px'}}>
                            <div>
                                <Col style={{ marginLeft: '45px' }}>
                                    <Col>
                                        <div className="main-header text-white text-size-16 mt-3 d-flex align-items-center">Total Volume ($)</div>
                                    </Col>

                                    {isLoading &&
                                        <Row className="my-3 mx-auto w-fc">
                                            <Spinner color='beplBlue' animation="border" />
                                        </Row>
                                    }
                                    {!isLoading &&
                                        <Row className="my-1">
                                            <div className="main-header text-size-36 text-white" >{highlightsData.length <= 0 ? 'N/A' : usCurrencyFormatter.format(highlightsData.total_volume)}</div>
                                        </Row>
                                    }
                                </Col>
                            </div>
                        </Card>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <Card style={{ backgroundColor: '#CF3F7C' , height: '110px'}}>
                            <div class="carousel-item active ">
                                <Col style={{ marginLeft: '45px' }}>
                                    <Col>
                                        <div className="main-header text-white text-size-16 mt-3 d-flex align-items-center">Locations</div>
                                    </Col>

                                    {isLoading &&
                                        <Row className="my-3 mx-auto w-fc">
                                            <Spinner color='beplBlue' animation="border" />
                                        </Row>
                                    }
                                    {!isLoading &&
                                        <Row className="my-1">
                                            <div className="main-header text-size-36 text-white">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_locations)}</div>
                                        </Row>
                                    }
                                </Col>
                            </div>
                        </Card>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <Card style={{ backgroundColor: '#CF3F7C' , height: '110px'}}>
                            <div class="carousel-item active ">
                                <Col style={{ marginLeft: '45px' }}>
                                    <Col>
                                        <div className="main-header text-white text-size-16 mt-3 d-flex align-items-center">Cases</div>
                                    </Col>

                                    {isLoading &&
                                        <Row className="my-3 mx-auto w-fc">
                                            <Spinner color='beplBlue' animation="border" />
                                        </Row>
                                    }
                                    {!isLoading &&
                                        <Row className="my-1">
                                            <div className="main-header text-size-36 text-white">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_cases)}</div>
                                        </Row>
                                    }
                                </Col>
                            </div>
                        </Card>
                    </Carousel.Slide>
                </Carousel>

            </Col>
        );
    } else if (isMobile) {
        return (
                <Col>
                    <Col className='ps-0'>
                    <div className='main-header text-size-20' style={{ lineHeight: '23px', fontWeight: '700', letterSpacing: '.0025em' }}>
                        Today's Highlights -  {highlightsOptions[carouselIndex].value}
                        <IoMdInformationCircle className="text-beplMagenta text-size-18" onClick={toggleModal} />
                    </div>
                    <Modal isOpen={modalOpen} toggle={toggleModal} style={{ height: '90%' }}>
                        <ModalHeader >
                            Today's Highlights
                        </ModalHeader>
                        <ModalBody>
                            <div className="mx-3">
                                <Row><p><b>Total Volume:</b> This section will show you your total volume, direct volume or BEPL volume within the timeframe you select from the date filters.</p></Row>
                                <Row><p><b>Locations:</b>  Your total locations (ie, individual store locations) within the selected date range.</p></Row>
                                <Row><p><b>Cases:</b>  Your total cases across all categories in the selected date range</p></Row>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={toggleModal} block className='py-2' style={{ float: 'right', backgroundColor: '#CF3F7C', color: 'white' }} >Close</Button>
                        </ModalFooter>
                    </Modal>
                </Col>
                <Carousel withIndicators height={160} withControls={true} controlSize={20} slideGap={3} controlsOffset="xs" onSlideChange={(index) => setCarouselIndex(index)}>
                    {highlightsOptions.map((option, index) => (
                        <Carousel.Slide key={index}>
                            <Row className="py-2" style={{ justifyContent: 'center' , marginRight: '0px', marginLeft: '0px'}}>
                                <Card style={{ backgroundColor: '#CF3F7C', height: '110px', maxWidth: '32%', marginRight: '3px' }}>
                                    <div>
                                        <Col style={{ marginLeft: '5px' }}>
                                            <Col>
                                                <div className="main-header text-white text-size-16 mt-3 d-flex align-items-center">Total Volume ($)</div>
                                            </Col>
                                            {isLoading && (
                                                <Row className="my-3 mx-auto w-fc">
                                                    <Spinner color='beplBlue' animation="border" />
                                                </Row>
                                            )}
                                            {!isLoading && (
                                                <Row className="my-1">
                                                    <div className="main-header text-size-32 text-white" >{highlightsData.length <= 0 ? 'N/A' : usCurrencyFormatter.format(highlightsData.total_volume)}</div>
                                                </Row>
                                            )}
                                        </Col>
                                    </div>
                                </Card>
                                <Card style={{ backgroundColor: '#CF3F7C', height: '110px', maxWidth: '31%', marginRight: '3px' }}>
                                    <div className="carousel-item active ">
                                        <Col style={{ marginLeft: '5px' }}>
                                            <Col>
                                                <div className="main-header text-white text-size-16 mt-3 d-flex align-items-center">Locations</div>
                                            </Col>
                                            {isLoading && (
                                                <Row className="my-3 mx-auto w-fc">
                                                    <Spinner color='beplBlue' animation="border" />
                                                </Row>
                                            )}
                                            {!isLoading && (
                                                <Row className="my-1">
                                                    <div className="main-header text-size-32 text-white">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_locations)}</div>
                                                </Row>
                                            )}
                                        </Col>
                                    </div>
                                </Card>
                                <Card style={{ backgroundColor: '#CF3F7C', height: '110px', maxWidth: '31%', marginRight: '3px' }}>
                                    <div className="carousel-item active ">
                                        <Col style={{ marginLeft: '5px' }}>
                                            <Col>
                                                <div className="main-header text-white text-size-16 mt-3 d-flex align-items-center">Cases</div>
                                            </Col>
                                            {isLoading && (
                                                <Row className="my-3 mx-auto w-fc">
                                                    <Spinner color='beplBlue' animation="border" />
                                                </Row>
                                            )}
                                            {!isLoading && (
                                                <Row className="my-1">
                                                    <div className="main-header text-size-32 text-white">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_cases)}</div>
                                                </Row>
                                            )}
                                        </Col>
                                    </div>
                                </Card>

                            </Row>
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Col>
        )
    }

    return (
        <Col className="py-2">
            <Row className="my-2" style={{width: '240px'}}>
                <Col>
                    <ReactSelect 
                        styles={selectStyles}
                        components={{DropdownIndicator, Option: InputOption}}
                        defaultValue={highlightsOptions[0]}
                        options = {highlightsOptions}
                        onChange = {(e) => onChangeHighlightsFilter(e.value)}
                    >
                    </ReactSelect>
                </Col>
            </Row>
            <Row style={{height: '40%'}}>
                <Col className='col-4' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">Total Volume ($)</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='This section will show you your total volume, direct volume or BEPL volume within the timeframe you select from the date filters' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" color={Theme().colors.accent} />
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isLoading &&
                    <Row className="my-2 mx-auto w-fc">
                        <Spinner color='beplBlue' animation="border"/>
                    </Row>
                    }
                    {!isLoading && 
                    <Row className="my-2">
                        <div className="main-header text-size-36">{highlightsData.length <= 0 ? 'N/A' : usCurrencyFormatter.format(highlightsData.total_volume)}</div>
                    </Row>
                    }
                </Col>
                <Col className='col-4' style={{borderRight: '1px lightgray solid'}}>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">Locations</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='Your total locations (ie, individual store locations) within the selected date range' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}} />
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isLoading && 
                    <Row className="my-2 mx-auto w-fc">
                        <Spinner color='beplBlue' animation="border"/>
                    </Row>
                    }
                    {!isLoading && 
                    <Row className="my-2">
                        <div className="main-header text-size-36">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_locations)}</div>
                    </Row>
                    }
                </Col>
                <Col className='col-4'>
                    <Row>
                        <Col>
                            <div className="main-header text-gray text-size-16">Cases</div>
                        </Col>
                        <Col className="w-fc">
                            <Tooltip placement='top' title='Your total cases across all categories in the selected date range' arrow>
                                <div>
                                    <IoMdInformationCircle className="text-size-18" style={{color: Theme().colors.accent}}/>
                                </div>
                            </Tooltip>
                        </Col>
                    </Row>
                    {isLoading &&
                    <Row className="my-2 mx-auto w-fc">
                        <Spinner color='beplBlue' animation="border"/>
                    </Row>
                    }
                    {!isLoading &&
                    <Row className="my-2">
                        <div className="main-header text-size-36">{highlightsData.length <= 0 ? 'N/A' : numberFormatter.format(highlightsData.total_cases)}</div>
                    </Row>
                    }
                </Col>
            </Row>
        </Col>
    )   
}

export default HighlightsCard;